@import 'styles/_theme.scss';

.banner {
  min-height: 120px;
  max-height: 120px;
  background-image: url('/assets/icons/banner.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  background-origin: content-box;
  padding: $ss-36 $ss-36 $ss-12 $ss-36;
  background-color: $lighterGrey;
  flex: 1;
  position: relative;
}

.bannerTitle {
  font-size: $ts-54;
  font-weight: 500;
  color: $darkPrimary;
  align-items: center;
  min-height: 100%;
}

.switchOrg {
  display: inline-flex;
}

.switchOrgLink {
  font-size: $ts-16;
  color: $primary;
  padding-top: 7px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.switchOrgIcon {
  padding: 5px 0;
  border-radius: 0px;
  fill: $black;
  opacity: 1;
  cursor: pointer;

  &:hover {
    background-color: unset;
    text-decoration: none;
  }
}

.switchOrgMenu {
  & :global(.MuiPaper-root) {
    max-width: 44em;
    background: $white 0% 0% no-repeat padding-box;
    opacity: 1;
  }
}

.menuitem {
  color: $darkestGrey;
  font-size: $ts-16;

  &:hover, &:focus {
    background-color: $lightGrey !important;
  }
}
