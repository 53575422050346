@import 'styles/_theme.scss';

.subscriptionContainer {
  padding: $ss-30 5.75em $ss-30 5.75em;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.sectionContainer {
  padding-bottom: $ss-45;
  //trying to work around MUI here
  max-width: 81.5%;
  @media screen and (max-width: 1670px) {
    max-width: 85%;
  }
  @media screen and (max-width: 1420px) {
    max-width: 90%;
  }
  @media screen and (max-width: 1245px) {
    max-width: 94%;
  }
}


.title {
  font-size: $ts-18;
  color: $darkPrimary;
  opacity: 1;
  padding-bottom: $ss-14;
}

.subTitle {
  font-size: $ts-16;
  color: $neutralGrey;
  opacity: 1;
  padding-bottom: $ss-20;
}

.colTitle {
  font-size: $ts-14;
  color: $neutralGrey;
  opacity: 1;
  padding-bottom: $ss-10;
  width: 205px !important;
  white-space: nowrap;
}

$ratio: calc( $ss-16 / $ss-12 );

.rowTitle {
  display: inline-block;
  min-width: $ss-94 * $ratio;
  max-width: $ss-94 * $ratio;
  margin-right: $ss-26 * $ratio;
  font-size: $ts-12;
  color: $neutralGrey;
  opacity: 1;
  padding-bottom: $ss-14 * $ratio;
  text-align: right;
  white-space: nowrap;
}

.data {
  font-size: $ts-16;
  color: $darkestGrey;
  opacity: 1;
  padding-top: $ss-2;
}

.noProduct {
  white-space: pre-line;
  float: left;
  vertical-align: top;
  margin-bottom: $ss-45;
  font-size: $ts-16;
  color: $darkestGrey;
  font-style: italic;
}

.warning {
  color: $red;
}

.alignRight{
  padding-top: $ss-4;
  padding-right: 30px;  
  width: 100px;
  text-align: right;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2 1;
}
.dividerContainer{
  max-width: 100px; 
  padding: 0;
}
.divider{
  background-color: $lightGrey;
}

.credentialButton{
  margin-top: $ss-10;
  width: 150px;
}
