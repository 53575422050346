@import 'styles/_theme.scss';

.dialog {
  & :global(.MuiPaper-rounded) {
    border-radius: $ss-8;
    border: 0.063em solid #707070;
  }
  & :global(.MuiPaper-elevation24) {
    box-shadow: 0px 30px 30px #00000033;
  }
  & :global(.MuiPaper-root) {
    max-width: 44em;
    background: $white 0% 0% no-repeat padding-box;
    opacity: 1;
  }
}

.dialogTitle {
  padding: $ss-12 $ss-16 0 $ss-16; //computed values are larger with the larger font-size
  font-size: $ts-25;
  color: $darkPrimary;
  opacity: 1;
  font-weight: 500;
  text-align: left;
}

.dialogContent {
  padding: 0 $ss-24 $ss-32 $ss-24;
  margin-top: $ss-18;
  max-height: 35em;
}

.dialogSectionTitle {
  padding-bottom: $ss-16;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0em;
  color: $darkestGrey;
  opacity: 1;
}

.divider {
  background-color: $mediumGrey;
  margin: $ss-8 0 $ss-20 0;
}

.dialogActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: $ss-8 $ss-8 $ss-8 0;
  border-top: 1px solid $neutralGrey;
}

.dialogContentText {
  font-size: $ts-16;
  padding-left: 0;
  margin-bottom: $ss-18;
  color: $neutralGrey;
}

.dialogSubSectionText {
  color: $neutralGrey;
  margin: 0px;
  opacity: 1;
  white-space: pre-wrap;
  line-height: 1.7;
  font: normal normal normal calc($ts-16 / $ts-25) Roboto;
  padding-bottom: $ss-16;
}
