@import 'styles/_theme.scss';

.rolesLegend {
    font-size: $ts-12;
    margin-bottom: $ss-28;
}

.noRoles {
    color: $mediumGrey;
    height: 100%;
}

.checkboxLabel{
    color: $darkestGrey;
    display: flex;
}


.checkbox {
    &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
    color: $darkPrimary !important;
    }
}