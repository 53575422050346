@import 'styles/_theme.scss';

.cardHeader {
    color: $darkPrimary;
}

:global(#createUserFormUserCard) {
    margin-top: -$ss-8;
}

.submitFormButtonContainer {
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
}

.submitFormButton {
    background-color: $primary;
    color: $white!important;
    text-transform: none;
    box-shadow: none;

    &:hover {
        background-color: $primary;
        box-shadow: none;
    }

    &.disabled {
        background-color: $primaryDisabled;
        &:hover {
            background-color: $primaryDisabled;
        }
    }
}       