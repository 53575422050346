@import 'styles/_theme.scss';

.dialogContent {
  padding: 0 0 $ss-10 0;
}

.highlightedText {
  background-color: $lightYellow;
  padding: $ss-12 $ss-24;
  line-height: $ss-30;
  white-space: pre-wrap;
  color: $darkestGrey;
}

.modalText {
  padding: 0 $ss-24;
  line-height: $ss-30;
  white-space: pre-wrap;
  color: $darkestGrey;
  font-size: $ts-16;
}

.textFieldContainer {
  margin: 0 $ss-24 $ss-4 $ss-24;
}

.modalBtnsContainer {
  margin: $ss-4 $ss-20 $ss-24 0;
}
