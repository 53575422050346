@import 'styles/_theme.scss';

.checkboxLabel{
    color: $darkestGrey;
    display: flex;
}

.checkbox {
    &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
    color: $darkPrimary !important;
    }
}