@import 'styles/_theme.scss';

.checkboxContainer {
    display: flex;
    align-items: center;
}

.checkbox {
    &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
        color: $darkPrimary !important;
    }
    &:hover {
        background-color: $slightlyLighterGrey;
    }
}