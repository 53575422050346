@import 'styles/_theme.scss';

.saveButton {
  margin-right: $ss-14;
}
.textDisplay {
  white-space: pre-line;
  float: left;
  vertical-align: top;
  font-size: $ts-16;
  color: $darkestGrey;
}

.edit {
  float: left;
  vertical-align: top;
  color: $darkestGrey;
  margin-top: -$ss-10;
}

.form {
  width: 50%;
  & :global(.MuiFilledInput-multiline.MuiFilledInput-marginDense) {
    padding-top: 0;
  }
}

.container {
  display: flex;
}

.buttonContainer {
  margin-top: $ss-16;
}

.noteInput {
  width: 400px;
  & :global(.MuiFilledInput-root) {
    line-height: $ss-24;
    padding: $ss-14 $ss-10 $ss-10 $ss-14;
  }
}
