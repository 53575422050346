@import 'styles/_theme.scss';

.rowContainer {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;

  &:global(.compact) {
    margin-bottom: $ss-32;
  }
  
  &:not(:global(.compact)) {
    margin-left: $ss-20;
  }
}

.trashIcon {
  margin-top: $ss-18;

  &:global(.compact) {
    margin-top: $ss-10;
  }
}

.compact {
  margin-right: $ss-24 !important;
}

.rowField {
  &:not(:last-child) {
    margin-right: $ss-24;
  }
  &:is(:global(.compact)) :global(.MuiAutocomplete-listbox) {
    max-height: 65px;
  }
  &:not(:global(.compact)) :global(.MuiAutocomplete-listbox) {
    max-height: 160px;
  }

  & :global(.MuiAutocomplete-popper),
  & :global(.MuiAutocomplete-paper) {
    background-color: $white;
    & li {
      color: $darkestGrey;
      &:hover {
        background-color: $lightGrey;
      }
    }
  }

  &:not(:global(.compact)) :global(.MuiInputLabel-root) {
    margin-bottom: $ss-28;
  }

  & :global(.MuiChip-root) {
    font-size: $ts-16;
    background-color: $darkGrey;
    color: $lighterGrey;

    & :global(.MuiSvgIcon-root) {
      color: $lighterGrey;
      font-size: $ts-16 !important;
      height: $ss-20;
      width: $ss-20;
    }
  }

  & :global(.MuiTextField-root) {
    background-color: $white;

    & :global(.MuiOutlinedInput-root) fieldset {
      border-color: $slightlyDarkGrey;
      color: $darkestGrey;
    }

    & :global(.MuiAutocomplete-endAdornment) {
      top: -2px;
      right: 0;
    }

    & :global(.MuiIconButton-root) {
      width: $px-44;
      height: $px-44;

      & :global(.MuiSvgIcon-root) {
        color: $neutralGrey;
      }

      &:hover {
        background-color: $lighterGrey;
      }
    }
  }
}

.rowFieldPopulated {
  & :global(.MuiTextField-root) {
    background-color: $slightlyLighterGrey;
  }
}
