@import 'styles/_theme.scss';

.fieldRow {
    width: 540px;

    & :global(.MuiGrid-root):not(:last-child) {
        margin-right: $ss-24;
    }

    &:first-child {
        padding-top: $ss-16;
    }

    &:not(:last-child) {
        margin-bottom: $ss-16;
    }

    &:last-child .userField {
        height: unset;
        min-height: $ss-56;
    }
}

.cardHeadline {
    font-size: $ts-20;
    color: $darkestGrey;
    height: $ts-24;
}

.cardDescription {
    border: 1px solid transparent;
    margin-top: $ss-36;
    color: $neutralGrey;
}
