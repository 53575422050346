@import 'styles/_theme.scss';

.cardContent {
  background-color: $white;
  &:hover {
    background: $lightestGrey;   
  }
 
}

.ul {
  list-style: none;
  margin: 0em;
  padding: 0em;
  background-color: $white;
}

.card {
  margin-bottom: $ts-16;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  box-shadow: 0px 3px 6px $blackShadow;
  min-height: 82px;

  & :global(.MuiCardContent-root):last-child {
    padding-bottom: 1rem;
  }
}
