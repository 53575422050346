@import 'styles/_theme.scss';

.rightAlignedButtonGroup {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 0 1.5rem 1.5rem;
}

.bodyText {
  color: $darkestGrey;
  text-transform: none;
  max-width: 60ch;
  white-space: pre-wrap;
  line-height: 2;
  padding-top: $ss-20;
}

.title {
  margin: 2px 0 0 0;
  padding-bottom: 0;
  color: $darkPrimary;
  text-transform: none;
  letter-spacing: 0;
  font-size: 25px;
  opacity: 1;
}

.fullName {
  font-weight: 600;
}
