@import 'styles/_theme.scss';

.data {
  color: $darkestGrey;
}

.buttonDivider {
  width: $ss-10;
  height: auto;
  display: inline-block;
}

.statusHeaderItem {
  padding: 0 $ts-14 0 0;
}

.detailItem {
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
}

.userDetailContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $ss-18 $ss-36;
  border: 1px solid $lighterGrey;
  border-radius: 8px;
  box-shadow: 0px 3px 6px $blackShadow;
  margin-top: -$ss-8;
}

.userDetail {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.userName {
  font-size: $ts-20;
  color: $darkestGrey;
  font-weight: 500;
}

.userEmail {
  color: $darkGrey;
  margin-top: $ss-20;
  font-size: $ts-16;
}

.startAndEndDate {
  margin-top: $ss-14;
}

.startDateSpan {
  margin-right: $ss-14;
  font-size: $ts-16;
  color: $darkGrey;
}

.endDateSpan {
  margin-left: $ss-14;
  font-size: $ts-16;
  color: $darkGrey;
}

.loginActivity {
  font-size: $ts-16;
  color: $darkGrey;
  margin-top: $ss-12;
}

.editButton {
  position: relative;
  bottom: 4px;
  color: $darkestGrey;
  padding: 8px;
  margin-left: 12px;
}

.cancelButtonSmall {
  color: $darkGrey;
  font-size: $ts-16;
  padding: $ss-4 $ss-18;
  margin-left: $ss-4;
}

.editUserContainer {
  padding: 0%;
}

.editUserBtnContainer {
  margin-bottom: $ss-20;
  margin-top: $ss-16;
}

.roleAssignmentCardHeader {
  padding: $ss-6 $ss-36;

  & :global(.MuiCardHeader-title) {
    font-size: $ts-20;
  }

  & :global(.MuiCardHeader-content) {
    flex-grow: 0;
  }

  & :global(.MuiIconButton-root):hover {
    background-color: $white;
  }
}

.roleAssignment {
  & :global(.MuiCardContent-root) :global(.MuiCardHeader-root) {
    padding-left: 0;
  }
}

.submitFormButtonContainer {
  margin-top: $ss-30;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
}
