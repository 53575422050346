@import 'styles/_theme.scss';

.srOnly {
  width: 0;
  height: 0;
  position: absolute;
  left: -10000px;
}

.dateField {
  max-width: 190px;
  margin-right: $ss-20 !important;

  :global(.MuiInputBase-root) {
    &:global(.Mui-disabled){
      opacity: 1;
      background-color: white;
      &::before{
        border-bottom: 1px solid white;
      }
      & button, & div, & input{
        opacity: 1;
        -webkit-text-fill-color: unset;
        color: $darkestGrey !important;
      }
    }
    padding-right: 0px !important;
    & input:disabled{
      background-color: white;
      padding-left: 0;
    }
  }

  :global(.MuiInputAdornment-root) {
    margin-left: 0;
    margin-right: 0;
  }

  :global(.MuiButtonBase-root):hover {
    background-color: $mediumGrey;
  }
}

.trim{
  max-width: 100px;
}

.dateFieldTo {
  color: $neutralGrey;
  margin-right: $ss-20;
  margin-top: $ss-24;
}

.dateFieldDialog {
  width: 250px;

  :global(.MuiButtonBase-root):not(:disabled) {
    color: $darkestGrey;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  :global(.MuiTypography-root) {
    width: $ss-28 * calc($ts-16 / $ts-12);
    font-size: $ts-12;
  }

  :global(.MuiCalendarPicker-root) {
    width: 250px;
    margin-left: 0;
    margin-right: 0;

    & :global(.MuiPickersArrowSwitcher-spacer) {
      width: $ss-12;
    }

    & > div:not(:global(.PrivatePickersFadeTransitionGroup-root)) {
      padding-right: $ss-3;
    }

    & :global(.MuiIconButton-root) {
      font-size: $ts-16;
      padding: 0;
      width: $ss-28;
      height: $ss-28;

      &:hover {
        border: 1px solid $darkPrimary;
        background-color: transparent;
      }

      &:focus {
        border: 2px dotted $darkPrimary;
        background-color: transparent;
      }
    }

    & :global(.MuiPickersDay-root):global(.Mui-selected) {
      background-color: $darkPrimary;
      color: $white;
    }

    & :global(.PrivatePickersFadeTransitionGroup-root) div,
    & :global(.MuiPickersFadeTransitionGroup-root) div {
      white-space: nowrap;
    }

    & :global(.MuiDayPicker-monthContainer) {
      overflow: visible;
      & :global(.MuiDayPicker-weekContainer):first-of-type {
        padding-top: 2px;
      }
    }

    & :global(.MuiCalendarPicker-viewTransitionContainer) {
      & > div {
        width: 250px;
        height: 230px;

        & :global(.PrivatePickersSlideTransition-root) {
          min-height: 190px;
          height: 190px;
        }
      }
    }
  }

  :global(.MuiPickersDay-root) {
    width: $ss-28;
    height: $ss-28;
    padding: 0;
    font-size: $ts-16;
    box-sizing: border-box;

    &:global(.MuiPickersDay-today) {
      border: 1px solid $darkestGrey;
    }

    &:hover {
      border: 1px solid $darkPrimary;
      background-color: transparent;
    }

    &:focus {
      &:global(.Mui-selected),
      &:global(.MuiPickersDay-today) {
        outline-offset: 2px;
        outline: 2px dotted $darkPrimary;
        z-index: 1000;
      }

      &:not(:global(.Mui-selected)):not(:global(.MuiPickersDay-today)) {
        border: 2px dotted $darkPrimary;
        background-color: transparent;
      }
    }
  }

  :global(.PrivatePickersYear-modeDesktop) {
    width: $ss-48;

    & :global(.PrivatePickersYear-yearButton) {
      width: $ss-48;
      padding: 0;
      box-sizing: border-box;

      &:hover {
        border: 1px solid $darkPrimary;
        background-color: transparent;
      }

      &:focus {
        border: 2px dotted $darkPrimary;
        background-color: transparent;
      }

      &:global(.Mui-selected) {
        background-color: $darkPrimary;
        color: $white;
      }
    }
  }
}
