@import 'styles/_theme.scss';

.rightAlignedButtonGroup {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 0 1.5rem 1.5rem;
}

.bodyText {
  color: $darkestGrey;
  text-transform: none;
  max-width: 60ch;
  white-space: pre-wrap;
  line-height: 2;
}

.title {
  padding-bottom: $ss-10;
  color: $darkPrimary;
  text-transform: none;
  letter-spacing: 0;
}

.menuItem {
  & :global(.MuiListItemIcon-root),
  & :global(.MuiListItemText-primary) {
    opacity: 1;
    font-size: $ts-16;
    letter-spacing: 0em;
  }
  & :global(.MuiListItemIcon-root) {
    min-width: 2em;
  }
}

.submitButton {
  margin-left: $ss-8;
}
