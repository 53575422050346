@import 'styles/_theme.scss';

.salesforceContainer{
    border: 1px solid $lighterGrey;
    border-radius: $ss-8;
    box-shadow: 0px 3px 6px $blackShadow;
    margin-bottom: $ss-50;
    margin-top: $ss-30;
}

.dataContent{
    margin-bottom: 2em;
}

.cardContent {
    padding: $ss-24 $ss-24 $ss-24 $ss-36;
}

.headerContainer{
    display: flex;
    align-items: center;
    background-color: $lightestGrey;
    margin: 0;
    padding: $ss-6 $ss-36;
}

.salesforceHeader{
    color: $darkestGrey;
    font-size: $ts-20;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    display: inline;
}

.data{
    color: $darkestGrey;
    margin: 0;
}

.header{
    font-size: $ts-12;
    color: $neutralGrey;
}