@import 'styles/_theme.scss';

.inlineBlock{
    display: inline-block;
}

.addPadding{
    padding-top: $ss-32;
}

.switch{
    padding-left: $ss-10;    
}
.switchLabel{
    padding-left: $ss-20;   
    color: $darkestGrey;
    font-weight: 600; 
}

.description{
    padding-left: 62px;
    color: $darkestGrey;
}

.checkbox {
    padding: 0;
    margin-right: $ss-8;
    &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
    color: $darkPrimary !important;
    }
}

.checkboxLabel{
    color: $darkestGrey;
    display: flex;
    padding-left: $ss-20;
}

.checkboxDescription{
    padding-left: $ss-58;
    color: $darkestGrey;
    font-size: $ts-14;
}

.radioGroupLabel{
    color: $darkestGrey; 
}

.radioGroup{
    margin-left: $ss-10;
}

.input{
    margin-bottom: $ss-16;
}
.helperText{
    font-size: $ts-12;
    color: $neutralGrey;
    padding-top: $ss-6;
}

.swap{
    margin-top: -$ss-42;
    padding-top: 0;
    margin-bottom: $ss-40;
}



.addAnotherButton {
    color: $primary;
    background-color: $white;
    font-weight: 400;
    margin-top: $ss-6;
    &:hover {
        background-color: $lighterGrey;
    }

    &:not(:global(.compact)) {
        margin-left: $ss-20;
    }
    :global(.MuiButton-startIcon){
        padding-bottom: $ss-2;
    }
}

.hide{
    display: none;
}

.error{
    :global(.MuiFilledInput-underline:after) {
      border-bottom: 2px solid $red;
    }
    :global(.MuiFormHelperText-root.Mui-error){
        padding-top: $ss-16;   
     
    }
  }  
.noSwap{
    :global(.MuiFormHelperText-root.Mui-error){
        padding-top: 0;
        margin-bottom: 0;
    }
}  

.flexContainer {
    display: flex;
}

.flexChild {
    flex: 1;  
} 

.inputField{
   width: 94%;
}

.trash{   
    margin-left: $ss-8; 
}