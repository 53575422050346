@import 'styles/_theme.scss';

.itemGrid {
  padding: 0 $ss-12 0 $ss-12;
  & legend{
    margin-bottom: $ss-14;
  }
}

.itemGridRowFieldPopulated {
  & :global(.MuiOutlinedInput-root) {
    background-color: $slightlyLighterGrey;
  }
}

.rowField {
  &:not(:last-child) {
    margin-right: $ss-24;
    margin-bottom: $ss-20;
  }

  & :global(.MuiAutocomplete-popper),
  & :global(.MuiAutocomplete-paper) {
    background-color: $white;
    & li {
      color: $darkestGrey;
      &:hover {
        background-color: $lightGrey;
      }
    }
  }

  & :global(.MuiChip-root) {
    font-size: $ts-16;
    background-color: $darkGrey;
    color: $lighterGrey;

    & :global(.MuiSvgIcon-root) {
      color: $lighterGrey;
      font-size: $ts-16 !important;
      height: $ss-20;
      width: $ss-20;
    }
  }

  & :global(.MuiTextField-root) {
    background-color: $white;

    & :global(.MuiInputBase-root) {
      color: $darkestGrey;
    }

    & :global(.MuiOutlinedInput-root) fieldset {
      border-color: $slightlyDarkGrey;
      color: $darkestGrey;
    }

    & :global(.MuiAutocomplete-endAdornment) {
      top: -2px;
      right: 0;
    }

    & :global(.MuiIconButton-root) {
      width: $px-44;
      height: $px-44;

      & :global(.MuiSvgIcon-root) {
        color: $neutralGrey;
      }

      &:hover {
        background-color: $lighterGrey;
      }
    }
  }
}

.gridItemDividr {
  padding-top: 15px;
}

.subscriptionTooltip {
  & :global(.MuiTooltip-tooltip),
  & :global(.MuiTooltip-tooltipArrow) {
    background-color: $black;
    opacity: 0.85 !important;
    color: $white;
  }
}

.warningBanner {
  background-color: $lightYellow;
  padding: $ss-4;
  margin-bottom: 3px;
  margin-top: $ss-10;
}

.warningTextTypography {
  color: $darkestGrey;
  margin: $ss-10 $ss-24;
  font-size: $ts-16;
}

.message {
  color: $neutralGrey;
  margin: $ss-10 $ss-24;
  font-size: $ts-16;
}

.closeButton {
  float: right;
}
