@import 'styles/_theme.scss';

.iconButton {
  margin-left: $ss-12;

  &:global(.MuiIconButton-root) {
    width: $px-44;
    height: $px-44;
    padding: 0;

    & :global(.MuiSvgIcon-root) {
        color: $neutralGrey !important;
    }

    &:hover {
        background-color: $lighterGrey;
    }
  }
}

.subscriptionTooltip {
  & :global(.MuiTooltip-tooltip), & :global(.MuiTooltip-tooltipArrow) {
      background-color: $black;
      opacity: 0.85 !important;
      color: $white;
  }
}