@import 'styles/_theme.scss';

.addNoteTextField{
     :global(.MuiInputBase-root) {
        background-color: $slightlyLighterGrey;
        color: $darkestGrey;
        border-radius: $ss-8 $ss-8 0 0;
        border-bottom: 1px solid $darkestGrey;
        padding-top: $ss-10;
        &:hover{
            background-color: $slightlyLighterGrey;
        }

        & textarea::placeholder{
            color: $darkestGrey;
            opacity: 1;
        }
    }   
}

.addNoteFooter{
 display: flex;
 justify-content: space-between;
 align-items: baseline;
 margin-top: $ss-4;
 color: $darkestGrey;
}

.charCount{
    margin-right: $ss-20;
}

.redText{
    color: $red;
}
.orgNotesform{
    grid-column: 1 / 2;
}