@import 'styles/_theme.scss';

.cardContent {
  background-color: $white;
  padding: 0 0 0 $ss-36;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  &:global(.MuiCardContent-root:last-child) {
    padding-bottom: 0;
  }

  .cardContentItem {
    width: 100%;
    display: flex;
    align-items: center;

    $ratio: calc( $ss-16 / $ss-12 );

    .rowTitle {
      display: inline-block;
      min-width: $ss-94 * $ratio;
      max-width: $ss-94 * $ratio;
      margin-right: $ss-26 * $ratio;
      font-size: $ts-12;
      color: $neutralGrey;
      opacity: 1;
      text-align: right;
      white-space: nowrap;
      align-self: baseline;
    }

    .data {
      font-size: $ts-16;
      color: $darkestGrey;
      opacity: 1;
      align-self: baseline;
    }
  }

  .itemGap {
    margin-top: $ss-10;
    margin-bottom: $ss-24;
  }
}

.card {
  margin: $ss-20 0;
  border: 1px solid $lighterGrey;
  border-radius: 8px;
  box-shadow: 0px 3px 6px $blackShadow;
}

.title {
  color: $darkPrimary;
  font-size: $ts-25;
  margin-right: $ss-8;
  display: block;
  font-weight: 400;
}

.wrapper {
  padding: $ss-16;
  padding-left: 0;
  width: 30em;
}

.textField {
  height: $ss-48;
  margin-bottom: $ss-14;
}

.cancelButton {
  margin-left: $ss-8;
}

.orgAdminBtn {
  margin: $ss-14 $ss-36 $ss-14 auto;
}


