@import 'styles/_theme.scss';

.footer {
  height: 200px;
  background-image: url('/assets/icons/content-footer.svg');
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  margin-top: $ss-45;
}
