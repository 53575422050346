@import 'styles/_theme.scss';

.filterContainer {
  align-items: baseline;
  background-color: $white;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.filterLabel{
  color: $neutralGrey;
  font-size: $ts-16;
  font-weight: 500;
}

.orgCategoryButton {
  padding: 4px 8px;
  border: 1px solid $darkerGrey;
  background-color: $white;
  color: $darkestGrey;
  font-size: $ts-14;
  font-weight: normal;
  text-transform: capitalize;
  &:global(.MuiToggleButton-root.Mui-selected) {
    color: $white;
    background-color: $neutralGrey;
    font-weight: normal;
    &:global(.MuiToggleButton-root.Mui-selected:hover) {
      background-color: $neutralGrey;
    }
  }
  &:global(.MuiToggleButton-root:hover) {
    background-color: $white;
  }
}
