@import 'styles/_theme.scss';

.restPasswordButton {
  color: $primary;
  background-color: $lighterGrey;
  text-transform: none; 
  font-size: $ts-16;
  font-weight: 400;
  line-height: $ts-16;
  padding: $ss-10;
  margin-top: $ss-10;
  &:hover {
    background-color: $lighterGrey;   
  }
}
