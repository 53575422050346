@import 'styles/_theme.scss';

.internalNotesPanelContainer{
    margin-top: $ss-50;
}

.noteSkeletonContainer {
    padding: 10px 30px 50px;
}

.notesContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $ss-32;
}

@media only screen and (max-width: 790px) {
    .notesContainer {
        grid-template-columns: 1fr;
    }
}
