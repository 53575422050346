@import 'styles/_theme.scss';

.snackBarContent {
  background-color: $darkerGreen !important;
  font-size: $ts-16;
  color: $white;
  width: 100%;
}

.snackBarError {
  background-color: $red !important;
}
