@import 'styles/_theme.scss';

.orgPlaceholderContainer {
  padding: $ts-24 $ts-40 0;
  letter-spacing: 0em;
  color: $darkestGrey;
  opacity: 1;
}

.helpfulText {
  margin-left: $ss-16;
  letter-spacing: 0em;
  color: $darkestGrey;
  opacity: 1;
}

.toolbar {
  background-color: $lightestGrey;
  height: 4.75em;
}

.status {
  border-radius: 0.813em;
  padding: $ss-4 $ss-12 $ss-4 $ss-12;
  font-size: $ts-12;
}
.active {
  background-color: #00a88733;
}
.inactive {
  background-color: #f4f4f4;
}
.actionCol {
  padding-right: $ss-20;
}

.menu {
  & :global(.MuiMenu-paper) {
    box-shadow: 0px 3px 6px #00000029;
  }
}

.subTable {
  & :global(.MuiTableBody-root .MuiTableCell-root):is(:first-child) {
    width: 4.625em;
  }
}

.menuItem {
  color: $darkestGrey;
  & :global(.MuiListItemIcon-root),
  & :global(.MuiListItemText-primary) {
    opacity: 1;
    font-size: $ts-16;
    letter-spacing: 0em;
  }
  & :global(.MuiListItemIcon-root) {
    min-width: 2em;
  }
}

.deactivate {
  color: $red;
}

.activate {
  color: $primary;
}
