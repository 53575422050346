@import 'styles/_theme.scss';

.container {
  background: 0% 0% no-repeat padding-box;
  background-color: $white;
  opacity: 1;
}

.spacer {
  padding-top: $ss-50;
}
