@import 'styles/_theme.scss';

.cardHeadline {
    font-size: $ts-20;
    color: $darkestGrey;
    height: $ts-24;
    margin-top: $ss-10;
    display: inline;
}

.cardDescription {
    border: 1px solid transparent;
    margin-top: $ss-24;
    color: $neutralGrey
}

.subscriptionSelector {
    padding: $ss-8;
}