@import 'styles/_theme.scss';

.applicationsTitle {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.iconOutline {
  border: 2px dotted grey;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  height: 95%;
}

.saveButtonWraper {
  display: flex;
  justify-content: flex-end;
}
