@import 'styles/_theme.scss';

.header {
  font-weight: bold;
}

.data {
  color: $darkestGrey;
}

.modalButton {
  background: $white;
  border: 1px solid $primary;
  color: $primary;
  float: right;
  margin-left: auto;
  border-style: solid;
  align-self: center;
  &:hover {
    background: $white;
  }
}

.gridItem {
  padding: 0 $ss-10 0 $ss-10;
}

.gridItemSalesForce {
  padding: 0 $ss-10 $ss-10 $ss-10;
}

.radioGroup {
  padding: $ss-10 0 0 $ss-10;
}

.inputAdornment {
  padding: 2px 0 0 0;
}

.textField {
  :global(.MuiInputBase-root) {
    height: $ss-42;
    border-radius: $ss-8 $ss-8 0 0;
    padding: $ss-3 $ss-3;

    &:hover {
      transform: none;
    }
  }

  :global(.MuiFilledInput-root) {
    background-color: $lightGrey;

    &:hover {
      transform: none;
      background-color: $lightGrey;
    }
  }
}

.closeButton {
  float: right;
}
