@import 'styles/_theme.scss';

.addSubscriptionSection {
    padding: $ss-16 0 0 0;
}

.addAnotherButton {
    color: $primary;
    background-color: $white;
    font-weight: 400;

    &:hover {
        background-color: $lighterGrey;
    }

    &:not(:global(.compact)) {
        margin-left: $ss-20;
    }
}