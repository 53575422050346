@import 'styles/_theme.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,-apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  color: $neutralGrey;
}
#root {
  height: 100%;
}
#main {
  &:focus {
    outline: none;
  }
}
//Override mui styles globally
.MuiTooltip-tooltip {
  font-size: $ts-14;
}

.MuiTab-root.Mui-selected {
  font-weight: bold;
  color: $white;
}

.MuiTableCell-paddingCheckbox:last-child {
  padding-right: $ss-36;
}

.MuiTypography-caption {
  color: $darkestGrey;
  font-size: $ts-14;
}

.MuiInputBase-root::after {
  border-bottom: none;
}

.MuiDialogTitle-root {
  .MuiTypography-h6 {
    font-size: $ts-25;
    color: $darkPrimary;
  }
}

.MuiButton-root {
  &:focus {
    outline: 2px dotted $darkerGrey;
    outline-offset: 2px;
    box-shadow: none;
  }
  .MuiTouchRipple-root {
    display: none;
  }
}

.MuiLink-root {
  &:focus {
    outline: 2px dotted $darkerGrey;
    outline-offset: 2px;
    box-shadow: none;
  }
}

.MuiToggleButton-root {
  &:focus {
    outline: 2px dotted $darkerGrey;
    outline-offset: 2px;
    box-shadow: none;
  }
  .MuiTouchRipple-root {
    display: none;
  }
}

.MuiTablePagination-toolbar {
  width: 98%;
  padding-right: 2%;
  background-color: $lightestGrey;
  .MuiIconButton-root {
    color: $darkerGrey;
    &:hover {
      background-color: $lightGrey;
    }
    &:disabled {
      color: #b5b5b5;
    }
  }
  .MuiTypography-caption {
    padding: 0 $ss-10 0 $ss-10;
  }
}
.MuiSelect-select.MuiSelect-select {
  background-color: $white;
  border-radius: $ss-8;
  padding: $ss-11 $ss-32 $ss-11 $ss-18;
  margin-right: $ss-20;
  color: $darkestGrey;
}
.MuiSelect-icon {
  padding-right: $ss-16;
}
.MuiSelect-iconOpen {
  transform: none;
}

.MuiIconButton-root {
  color: $darkestGrey;
}

.MuiTableSortLabel-root {
  &:focus {
    outline: 2px dotted $darkerGrey;
    outline-offset: 2px;
    box-shadow: none;
  }
}

.MuiTablePagination-displayedRows {
  display: none;
}

.MuiTablePagination-select {
  &:focus{
    outline: 2px dotted $darkerGrey;
  }
}

.MuiIconButton-root { 
  &:focus {
    outline: 2px dotted $darkerGrey;
  } 
}

.react-list-editable .input-field {
  width: 500px !important;
}
