@import 'styles/_theme.scss';

.inputLabel {
  font-size: $ts-12;
  margin-bottom: $ts-8;
  color: $darkestGrey;
}

// Only way I could figure out how to implement the dashed line, no way to talk
// to the .Mui components that wrap the TextField.
.muiWrapper {
  margin: 0;
  box-shadow: none;
  padding: 0 0 0 0;
  flex-grow: 1;
  & > :global(.MuiInputLabel-root) {
    font-size: $ts-12;
    margin-bottom: $ts-8;
    color: $darkestGrey;
  }
}

// TODO Cannot figure out how to apply the border-radius
.muiTextField {
  border-radius: 4 4 0 0;
  color: $black;

  & input::placeholder {
    color: $darkestGrey;
    opacity: 1;
  }
  & input:focus::placeholder {
    opacity: 0.5;
  }

  :global(.MuiFilledInput-underline:before) {
    border-bottom: 2px solid $darkestGrey;
  }

  :global(.MuiFilledInput-underline:after) {
    border-bottom: 2px solid $black;
  }

  :global(.MuiFormHelperText-root) {
    color: $darkestGrey;
  }
  :global(.MuiFormHelperText-root.Mui-focused) {
    color: $black;
  }
  :global(.MuiFormHelperText-root.Mui-error) {
    color: $red;
    margin-left: 0;
  }

  :global(.MuiInputLabel-root) {
    color: $darkestGrey;
  }
  :global(.MuiInputLabel-root.Mui-focused) {
    color: $black;
  }
  :global(.MuiInputLabel-root.Mui-error) {
    color: $red;
  }

  :global(.MuiFilledInput-root) {
    transform: none;
    background-color: $lightGrey;
  }
  :global(.MuiFilledInput-root:focus) {
    background-color: $lightGrey;
  }
  :global(.MuiFilledInput-root:hover) {
    background-color: $lightGrey;
  }

  & :global(.Mui-disabled),
  & :disabled {
    opacity: 0.5;
  }
}

.baselineTextField {
  :global(.MuiInputBase-root) {
    background-color: $lightGrey;
    border-radius: 8px 8px 0 0;
    padding-right: $ss-16;

    &:focus-within {
      outline-offset: 2px;
      outline: 2px dotted $black;
    }
  }
  :global(.MuiInputBase-input) {
    padding: $ss-10 0 $ss-10 $ss-16;
    border-radius: 8px 8px 0 0;
    background-color: $lightGrey;
    border-radius: 8px 8px 0 0;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $lightGrey inset;
    box-shadow: 0 0 0px 1000px $lightGrey inset;
  }

  :global(.MuiInputAdornment-positionStart) {
    margin-top: 0 !important;
  }
}
