@import 'styles/_theme.scss';

.card{
    border: 1px solid $mediumGrey;
    border-radius: 8px;
    box-shadow: 0 3px 6px $lightGrey;
    margin-bottom: $ss-16;
  }
  
  .cardModule {
    padding-left: $ss-40;  
    padding-right: $ss-24;  
    display: flex;   
  }

  .cardModuleExpand{
    background-color: $lightestGrey;
  }

  .moduleCheckboxLabel{ 
    color: $darkestGrey;
    :global(.MuiTypography-root) {
      font-size: $ts-20;
    }
  }

  .moduleCheckBox{
    width: 18px;
    height: 18px;
    opacity: 1;
    padding: 0;
    margin-right: 1rem;
    margin-left: 0.8rem;
    &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
      color: $darkPrimary !important;
    } 
  }
  
  .cardActions{
    margin-left: auto; 
  }
  
  .cardContent{
    padding-left: $ss-40;
    padding-right: $ss-40;
  }
  
  .gridItemDividr {
    padding-top: $ss-4;
  }
  
  .appName{
    font-size: $ss-18;
  }

  .selectionContainer{
    margin-bottom: $ss-10;
  }
  
  .selectionName{
    padding: $ss-10 0 $ss-10 0;
    font-size: $ss-12;
  }
  
  .selectionOptions{
    margin-left: $ss-10;
  }
  
  .redoxInput {
    padding: $ss-14 0 $ss-10 0;
    margin-bottom: $ss-16;
    width: 94%;
  }

  .textInput {
    padding: $ss-14 0 $ss-10 0;    
    width: 94%;
  }
      
  .inputError{
    :global(.MuiFilledInput-underline:after) {
      border-bottom: 2px solid $red;
    }
  }
  
  .apiTitle{
    font-weight: 400;
    font-size: $ts-14;
    color: $neutralGrey;
  }
  .apiWarning{
    font-weight: 700;   
    color: $darkestGrey;
    padding-left: $ss-10;

  }
  .apiWarning2{
    font-weight: 400;  
    color: $neutralGrey;
    padding-left: $ss-26;
    padding-top: $ss-4;
  }

  .apiWarningIcon{
    width: $ss-12;
    height: $ss-12; 
    position: relative; 
    top: 4px;
  }

  .warningContainer{
    margin-top: $ss-4;
    font-size: $ts-16;
  }

  .apiKeyContainer{
    border: 1px solid $white;
    border-radius: $ss-8;
    background-color: $lightestGrey;
    padding: $ss-24;
    min-height: $ss-40;
    margin-top: $ss-10;
  }

  .apiName{
    font-size: $ts-16;
    font-weight: 500;
    color: $darkestGrey;
  }
  .apiDescription{
    padding-top: $ss-10;
    font-size: $ts-14;
    color: $darkestGrey;
  }

  .apiCreated{
    padding-top: $ss-10;
    font-size: $ts-14;
    font-weight: 400;
    color: $neutralGrey;
  }

  .status {
    border-radius: 0.813em;
    padding: $ss-4 $ss-12 $ss-4 $ss-12;
    font-size: $ts-12;
    margin-left: $ts-20;
  }
  .active {
    background-color: #CCEEE7;
    ;
  }
  .inactive {
    background-color: $lightGrey;
  }
  .apiButton{
    float: right;
  }

  .greenLink {
    margin-top: $ss-10;
    color: $primary-color;
    font-size: $ts-14;
    text-decoration-color: $primary-color;
    &:hover {
      color: $primary-color;
    }
  }
  
  