@import 'styles/_theme.scss';

.overrideActions{
  display: block;
  text-align: right;
  padding: $ss-8 $ss-8 $ss-8 0;
}

.errorMessage{
  color: $red;
  font-size: $ts-14;
  display: block;
  margin-bottom: $ss-12; 
}

.divider{
  background-color: $lightGrey;
  margin-bottom: $ss-30;
  margin-top: $ss-30;
}

.sectionTitle {
  padding-bottom: $ss-16;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0em;
  color: $darkestGrey;
  opacity: 1;
}

.subSectionText {
  color: $neutralGrey;
  margin: 0px;
  opacity: 1;
  white-space: pre-wrap;
  line-height: 1.7;
  font: normal normal normal calc($ts-16 / $ts-25) Roboto;
  padding-bottom: $ss-16;
}

.divider {
  background-color: $mediumGrey;
  margin: $ss-40 0 $ss-40 0;
}
.subscriptionTitle{
  color: $darkestGrey;
  font-size: $ts-20;
  font-weight: 700;
  padding-bottom: $ss-24;
}

.subName{
  font-weight: 400;
  padding-left: $ss-10;
}
