@import 'styles/_theme.scss';

.orgNameText {
  font-size: $ts-20;
  color: #424242;
  opacity: 1;
  margin: 0;
}

.orgInformation {
  padding-left: $ss-25;
  padding-bottom: $ss-10;
  display: flex;
  align-items: center;
  margin: 0;
}

.orgContainer {
  display: flex;
  justify-content: space-between;
  margin: 5px 25px 5px 5px;
}

.orgActions {
  align-self: center;
  margin: 0;
}
.chip {
  color: $darkestGrey;
  border-radius: $ss-16;
  font-size: $ts-14;
  border-radius: $ss-16;
  font-weight: normal;
  margin-left: 20px;
  height: 32px;
  padding: 8px;
}
