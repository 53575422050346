@import 'styles/_theme.scss';

.container {
  z-index: 1100;
  min-height: 60px;
  background-color: $white;
  padding-bottom: $ss-18;
}
.title {
  color: $darkestGrey;
  font-size: $ts-20;
  font-weight: 400;
  display: block;
  margin-bottom: $ss-10;
}
.secondary {
  color: $neutralGrey;
  padding-bottom: $ss-12;
  display: block;
  font-size: $ts-16;
}

.third {
  color: $neutralGrey;
  padding-bottom: $ss-12;
  display: block;
  font-size: $ts-16;
  font-style: italic;
}
