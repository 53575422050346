@import 'styles/_theme.scss';

.checkboxChipSelected{  
    background: #D3F0E7 0% 0% no-repeat padding-box; 
}

.checkboxChip{    
    border: 1px solid $darkestGrey;
    border-radius: $ss-20;
    opacity: 1;
    height: $ss-40;
    padding-left: $ss-14;
    padding-right: $ss-14;
    margin-bottom: $ss-10;
    color: $darkestGrey;
    :global(.MuiTypography-root) {
        font-size: $ts-14;        
    }     
    & :disabled {
        color: rgba(0, 0, 0, 0.5);
    }    
}

.checkboxChip:focus-within{
    outline: 2px dotted $darkerGrey;
    outline-offset: 2px;
}

.check{
    color: $darkestGrey;         
}

.uncheck{
    color: transparent;   
    margin-right: -30px;  
}