@import 'styles/_theme.scss';

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $ss-24;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: $ss-12;
  padding-bottom: $ss-12;
}

.column {
  display: flex;
  flex-direction: column; 
  padding-right: $ss-20; 
  padding-bottom: $ss-48;
  min-width: 250px;
}

.right {
  display: flex;
  flex-direction: column; 
  padding-bottom: $ss-48; 
}

.title {
  font-size: $ts-24;
  color: $darkPrimary;
  display: inline-block;
  padding-bottom: $ss-12;
  margin: 0;
  font-weight: 400;
}

.admin {
  display: flex;
  justify-content: left;
  align-items: center;
}

.adminIcon {
  padding-right: $ss-12;
  width: $ss-32;
}

.item {
  font-size: $ts-16;
  line-height: $ss-30;
  font-weight: 500;
  white-space: nowrap;
}

.cardIcon {
  width: $ss-56;
}

.manage {
  padding-bottom: $ss-20;
}

