@import 'styles/_theme.scss';

.statusChip {
  width: auto;
  font-size: $ts-12;
  margin-right: $ss-16;
  padding: 3px 0;
  background-color: $lightGreen;
  color: $darkestGrey;
  width: fit-content;
  height: 22px;
  text-transform: uppercase;

  & :global(.MuiChip-label) {
    line-height: normal;
  }

  &:global(.active) {
    background-color: #{$green}33;
  }

  &:global(.staged), &:global(.resendinvite) {
    background-color: $lightYellow;
  }

  &:global(.suspended), &:global(.inactive) {
    background-color: $lighterGrey;
  }

  &:global(.provisioned), &:global(.invitesent) {
    background-color: $lightGreen;
  }

  &:global(.deprovisioned), &:global(.removed) {
    background-color: #{$red}33;
  }

  &:global(.invitepending) {
    background-color: $lightRed;
  }
}