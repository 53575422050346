@import 'styles/_theme.scss';

.secondaryHeaderWrapper {
  display: flex;
  justify-content: space-between;
}

.btnText {
  padding-left: $ss-12;
  white-space: nowrap;
}

.secondaryHeaderText {
  margin-top: $ss-24;
}

.noDelegatesText {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: italic;
  color: $darkestGrey;
  font-size: $ts-18;
}
