@import 'styles/_theme.scss';

.userCard {
    border: 1px solid $mediumGrey;
    border-radius: 8px;
    box-shadow: 0 3px 6px $lightGrey;
    margin-bottom: $ss-16;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.cardHeaderContainer {
    background: $white;
    display: flex;
    align-items: center;
    padding: $ss-16 $ss-24 $ss-20 0;
}

.cardActions{
    padding-left: $ss-20;
    padding-right: $ss-20;
}

.cardHeaderExpanded {
    background-color: #f9f9f9;
}

.cardHeader{
    font-size: $ts-20;
    font-weight: 500;
    color: $darkestGrey;
    margin: 0;
}

.cardSubHeader{
    font-size: $ts-16;
    color: $neutralGrey;
}

.btnContainer{
    margin-right: $ss-16;
    margin-left: auto;
}

.btnWrapper {
    display: inline;
    margin-right: $ss-8;
}

.cardContentContainer {
    padding: $ss-16 1.5em $ss-16 4.5em;
    color: $darkestGrey;
}

.cardHeaders{
    font-size: $ts-12;
}

.cardSubSection{
 padding-top: $ss-16; 
}

.notFirst {
    border-top: 1px solid $lightGrey;
    margin-top: $ss-16;
}

.roleChip {
    font-size: 14px;
    color:#676767;
    background-color: #EBEBEB;
}

.chipContainer{
    gap: $ss-10;
}