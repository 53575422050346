@import 'styles/_theme.scss';

.appBar {
  background: $darkPrimary;
  color: $white;
  box-shadow: none;
  height: 72px;
  flex: 0 1 auto;
  position: sticky;
  top:0;
  z-index: 1100;
  width: 100%;
}
.skipLink{
  position: absolute;
  outline: 1px solid $white;
  color: $darkPrimary;
  font-size: $ts-12;
  padding: $ss-16;
  &:focus {
    position: relative;
  }
}
.logo {
  width: 326px;
  padding-top: 10px;
}
.logoWrapper{
  height: 68px;
  &:global(.MuiTypography-root):focus-visible{
    outline: none;
    border: 2px dashed $white;
  }
}
.profile {
  background: $primary-color;
  height: 72px;
  margin-right: -24px;
  min-width: 180px;
}
.accountIcon {
  color: $white;
  font-size: $ts-38;
  vertical-align: middle;
  display: inline-flex;
  padding-right: $ss-8;
}
.account {
  margin: $ss-16;
}
.tool {
  color: $white;
  font-size: $ts-12;
  font-style: italic;
  padding-top: $ss-2;
}

.name {
  font-size: $ts-18;
  line-height: $ss-12;
}

.tab {
  text-transform: capitalize;
  height: 72px;
  font-size: $ts-18;
  font-weight: 300;
  color: $white;
  opacity: 1;
  &:hover {
    background-color: $primary-color;
  }
  &:focus-visible {
    border: 2px dashed $white;
  }
}

.tabIndicator {
  height: '4px';
}

.tabButton{
  min-height: 42px;
  text-transform: capitalize;
  font-size: $ts-16;
  border-radius: $ss-20;
  margin: $ss-15 $ss-20 0 $ss-20;
  letter-spacing: 0.13px;
  padding-left: 18px;
  padding-right: 18px;
  opacity: 1;
}

.tabButtonUnselected {
  font-weight: 500;
  color: $darkPrimary;
  background-color: $white;
  &:focus-visible {
    border: 2px dotted $primary-color;
  }
}

.tabButtonSelected {
  color: $white;
  border: 2px solid #FFFFFF;
  background-color: $darkPrimary;
  &:focus-visible {
    border: 2px dashed $white;
  }
}

@media only screen and (max-width: 700px) {
  .profile {
    background: $primary-color;
    height: 72px;
    width: 80px;
  }
  .logo {
    min-width: 120px;
    padding-top: 12px;
  }
  .tool{
    display: none;
  }
  .accountIcon{
    display: none;
  }
}
