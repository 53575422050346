@import 'styles/_theme.scss';


.modalButtonText {
  margin-left: 5px;
  text-transform: none;
  letter-spacing: 0;
  color: #0d8484;
  opacity: 1;
  font-size: 1rem;
}

.closeButton {
  float: right;
}

.overrideActions{
  display: block;
  text-align: right;
  padding: $ss-8 $ss-8 $ss-8 0;
  border-top: 1px solid $neutralGrey;
}

.errorMessage{
  color: $red;
  font-size: $ts-14;
  display: block;
  margin-bottom: $ss-12; 
}

.divider{
  background-color: $lightGrey;
  margin-bottom: $ss-30;
  margin-top: $ss-30;
}