@import 'styles/_theme.scss';

.createUser {
  text-transform: capitalize;
  font-size: $ts-16;
  float: right;
  z-index: 1000;
  background-color: $primary;
  color: $white;
  box-shadow: none;
  margin: $ss-12 $ss-16 0 $ss-45;
  border-radius: $ss-8;
  line-height: $ss-20;
  height: $ss-42;
  padding: $ss-16;
  &:hover {
    background-color: $primary;
    box-shadow: none;
  }
}

.redMenuItem {
  color: $red;
  p {
    padding-left: 10px;
  }
}
.greenMenuItem {
  color: $darkPrimary;
  p {
    padding-left: 10px;
  }
}

.greyMenuItem {
  color: $darkestGrey;
  p {
    padding-left: 10px;
  }
}

.toolbar {
  background-color: $lightestGrey;
  height: 76px;
  display: flex;
  justify-content: flex-end;
  align-items: right;
}

.menu {
  & :global(.MuiMenu-paper) {
    box-shadow: 0px 3px 6px #00000029;
  }
}

.tableContainer {
  & :global(.MuiInputBase-input) {
    &:focus-visible {
      outline: 2px dotted $darkerGrey;
      outline-offset: 2px;
    }
  }
}

.btnText {
  padding-left: $ss-12;
  white-space: nowrap;
}
