@import 'styles/_theme.scss';

.dialogContent {
  overflow: hidden;
  padding-bottom: 0;
  margin-top: $ss-8;
}

.textField {
  :global(.MuiInputBase-root) {
    background-color: $lightGrey;
    border-radius: $ss-8 $ss-8 0 0;
    padding: 1px $ss-16;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $lightGrey inset;
    box-shadow: 0 0 0px 1000px $lightGrey inset;
  }
}

.nameInputs {
  padding-top: $ss-16;
  padding-bottom: $ss-12;
}

.lastNameInput {
  min-width: 303px;
}

.firstNameInput {
  min-width: 303px;
  padding-bottom: $ss-16;
}

.createButton {
  float: right;
  margin: $ss-16 38px 0 0;
}

.closeButton {
  float: right;
}

.lifespan {
  padding-top: $ss-8;
  padding-bottom: $ss-20;
}

.warningBanner {
  color: $darkGrey;
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: $ts-12;
  line-height: 14px;
  font-weight: 400;
}

.errorBanner {
  color: $red;
  margin-top: 4px;
  font-size: $ts-12;
  line-height: 14px;
  font-weight: 400;
}

.invalidEmailBanner {
  color: $red;
  font-size: $ts-12;
  line-height: 14px;
  font-weight: 400;
}
