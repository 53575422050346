@import 'styles/_theme.scss';

.rules {
  padding-left: $ss-16;
  margin: 0;
  list-style: none;
}

.ruleItem {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: $ss-6;
  }

  & svg {
    padding-right: $ss-8;
  }
}

.valid {
  color: $primary;
}

.invalid {
  color: $neutralGrey;
}