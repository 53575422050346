@import 'styles/_theme.scss';

.container {
  z-index: 1100;
  min-height: 60px;
  background-color: $white;
  padding-top: $ss-36;
}
.title {
  color: $darkPrimary;
  font-size: $ts-25;
  margin: 0;
  padding-bottom: $ss-12;
  display: block;
  font-weight: 400;
}
.secondary {
  color: $neutralGrey;
  padding-bottom: $ss-24;
  display: block;
  font-size: $ts-16;
}
