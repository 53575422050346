@import 'styles/_theme.scss';

.noteCard {
    border: 1px solid $mediumGrey;
    border-radius: 8px;
    box-shadow: 0px 3px 6px $blackShadow;
}

.cardHeader {
    padding: $ss-18 $ss-18 $ss-10;
    & :global(.MuiCardHeader-title) {
        font-size: $ts-16;
        font-weight: 500;
        margin-bottom: 5px;
    }
    & :global(.MuiCardHeader-subheader) {
        color: $neutralGrey;
        font-size: $ts-12;
    }
}

.cardContent {
    padding: 0 $ss-18 $ss-18;
}

.noteText {
    font-size: $ts-16;
    color: $darkestGrey;
    margin: 0;
    line-height: $ts-25;
    white-space: pre-wrap;
}

.textBtn {
    display: block;
    border: none;
    background-color: $white;
    text-decoration: underline;
    color: $primary;
    font-size: $ts-16;
    line-height: $ts-25;
    padding: 0;
    cursor: pointer;
}
.cutOff{
    height: 125px;
    width: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.showAll{
    display: inline-block;
    overflow: visible;
    height: auto;
}